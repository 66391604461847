
import Vue from 'vue';

import { Models, Services } from '../../injectables/tokens';
import {
  AuthServiceContract,
  C360ModelContract,
  EnvServiceContract,
  LocalStorageServiceContract,
  TrackerContract,
} from '../../injectables';
import { Routes } from '../../router/routes';

export default Vue.extend({
  name: 'AHLogin',

  useInjectable: [Services.Auth, Services.LocalStorage, Services.Env, Models.C360, Services.Tracker],

  created() {
    if (this.$route.query?.token) {
      this.getAccessToken(this.$route.query.token as string);
    }
  },

  methods: {
    currentEnv() {
      return (this[Services.Env] as EnvServiceContract).getEnvironmentName(true);
    },
    redirectToAH(): void {
      const link = (this.c360Entity as C360ModelContract).getC360LoginLink(this.currentEnv());
      window.location.href = link;
    },
    async getAccessToken(refreshToken: string) {
      const { isErr, unwrap } = await (this.authService as AuthServiceContract).refreshToken(refreshToken);

      if (isErr()) {
        (this[Services.LocalStorage] as LocalStorageServiceContract).removeItem('mp_c360_refresh');
        this.redirectToAH();
        return;
      }

      (this[Services.LocalStorage] as LocalStorageServiceContract).setItem('mp_c360_refresh', refreshToken);

      const data = unwrap();

      if (data.access) {
        this.handleAccessToken(data.access);
      } else {
        this.redirectToAH();
      }
    },
    async handleAccessToken(token: string): Promise<void> {
      (this[Services.LocalStorage] as LocalStorageServiceContract).setItem('mp_c360_access', token);

      const user = await this.$store.dispatch('auth/checkIfAuth');

      (this.tracker as TrackerContract).trackUserLogin({
        agency: user.agencyName || '',
        email: user.email,
        id: user.id,
      });

      const nextUrl = this.$route.query.redirectUri;

      if (!nextUrl) {
        window.location.href = '/';
      } else {
        window.location.href = nextUrl;
      }
    },
    async getUserByEmail(email: string) {
      const { isErr, unwrap } = await (this.authService as AuthServiceContract).getUserByEmail(email);

      if (isErr()) {
        this.redirectToAH();
        return;
      }

      const data = unwrap();

      return data;
    },
  },
});
